import React, { useState, useEffect } from 'react';
import './AllStakingList.css'; // Updated CSS filename
import { allstaking, getWallet } from './helper/api';
import { useSelector } from 'react-redux';

const AllStakingList = () => {
  const [stakingList, setStakingList] = useState([]);
  const { userId } = useSelector((state) => state.user.value);
  const { wallet_address } = useSelector((state) => state.user.value);

  // Fetch staking history when address is available
  useEffect(() => {
    const fetchStakingHistory = async () => {
    
      try {
        console.log("address ",wallet_address)
        const data = { wallet_address: wallet_address };
        const response = await allstaking(data);
        console.log(response);
        setStakingList(response);
      } catch (error) {
        console.error('Error fetching staking history:', error);
      }
    };

    fetchStakingHistory();
  }, [wallet_address]);


  return (
    <div className="staking-list-screen">
      <div className="logo-section">
        <img src="/new2.png" alt="Logo" className="logo-img" />
      </div>

      <div className="staking-list">
        <h3>My All Staking</h3>
        {stakingList.length === 0 ? (
          <p>No staking records found.</p>
        ) : (
          <table className="staking-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Amount</th>
                <th>Transaction Hash</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {stakingList.map((stake, index) => (
                <tr key={stake._id}>
                  <td>{index + 1}</td>
                  <td>${stake.amount}</td>
                  <td>
                    <a 
                      href={`https://bscscan.com/tx/${stake.txHash}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {stake.txHash?.slice(0, 6)}...{stake.txHash?.slice(-6)}
                    </a>
                  </td>
                  <td>{new Date(stake.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AllStakingList;
