import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';  // Update import
import './PaymentScreen.css';
import { useSelector } from 'react-redux';
import { gettransaction, guestRegister, starttracking } from './helper/api';

const MySwal = withReactContent(Swal);

const PaymentScreen = () => {
  const location = useLocation();
  const amount = location.state?.amount || 0; // Fallback to 0 if amount is undefined
  const { wallet_address } = useSelector((state) => state.user.value);
  const { userId } = useSelector((state) => state.user.value);
  const [transcount, setTrans] = useState();
  const [ address , setWallet] = useState(wallet_address)

 
  const handleCopyClick = () => {
    const textToCopy = address;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("Address copied to clipboard!");
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        wallet_address : address
      }
      gettransaction(data).then((res) => {
        console.log("new count ",res?.data)
        console.log("transcount ",transcount)
        if(res){
        if(transcount != res?.data){
          MySwal.fire({
            title: 'Payment Success!',
            text: 'Your payment was processed successfully!',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        }
      }
      })
    }

    const intervalId = setInterval(() => {
      fetchData();
    }, 30000); // 30 seconds

    return () => clearInterval(intervalId); // Clean up on unmount
  }, [address,transcount]);


  useEffect(()=>{
    const data = {
      wallet_address : address
    }
    if(userId){
    gettransaction(data).then((res) => {
      console.log(res)
      setTrans(res?.data)
    })
    }
  },[address])

  useEffect(()=>{
    const data = {
      address : address
    }
    starttracking(data).then((res) => {
      console.log(res)
    })
  
  },[userId,address])
  
  return (
    <div className="payment-screen">
      <div className="payment-card">
        <h2>Complete Your Payment</h2>

        <div className="qr-section">
          <QRCodeCanvas
            value={address}
            size={200}
            includeMargin={true}
            bgColor="#ffffff"
            fgColor="#000000"
          />
          <div className="amount-section">
            <h3>{amount.toFixed(6)} USDT</h3>
            <p>Payment Address: {address?.slice(0,6)}...{address?.slice(-6)}</p>
            <button className="copy-button" onClick={handleCopyClick}>
              COPY ADDRESS
            </button>
          </div>
        </div>

        <div className="order-details">
          <h3>Order Details</h3>
          <div className="details-row">
            <span>Deposit:</span>
            <span>{amount.toFixed(6)} USDT</span>
          </div>
          <div className="details-row">
            <span>Received:</span>
            <span>0.000000 USDT</span>
          </div>
          <div className="details-row">
            <span>Status:</span>
            <span>Pending</span>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PaymentScreen;
