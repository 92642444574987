import React from 'react';
import { Link } from 'react-router-dom';
import BottomSheet from './BottonSheet'; // Import the BottomSheet component
import './NavBar.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { setBottomSheetOpen } from './helper/redux/dataSlice';

const NavBar = () => {
  const { bottomSheet } = useSelector((state) => state.user.value);
  const dispatch = useDispatch()
  function closeBottom(){

    dispatch(setBottomSheetOpen({ bottomSheet: false }));

  }
  return (
    <nav className="nav-bar">
      <ul className="nav-items">
        <li>
          <Link to="/" className="nav-link" onClick={closeBottom}>
            <i className="fas fa-home"></i>
            <span className="nav-text">Home</span>
          </Link>
        </li>
        <li>
          <Link to="/mine" className="nav-link" onClick={closeBottom}>
            <i className="fas fa-gem"></i>
            <span className="nav-text">Stake</span>
          </Link>
        </li>
        <li>
          <Link to="/partner" className="nav-link" onClick={closeBottom}>
            <i className="fas fa-users"></i>
            <span className="nav-text">Partners</span>
          </Link>
        </li>
        <li>
          {/* Update this to trigger the BottomSheet */}
          <BottomSheet />
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;