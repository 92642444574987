import React, { useContext, useEffect, useState } from 'react';
import './Dashboard.css'; // We'll create this file for styling later
import { TelegramContext } from './TelegramContext';
import { Link, useSearchParams } from 'react-router-dom';
import { checkRank, guestRegister } from './helper/api';
import { setUserId, setWalletAddress } from './helper/redux/dataSlice';
import { useDispatch, useSelector } from 'react-redux';

const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const [dailyIncome, setdeliincome] = useState('0.000');
  const [walletIncome, setstkincome] = useState('0.000');
  const [mystakings, setStaking] = useState();
  const [rank, setrank] = useState('Member');
  const [sponcer, setsponcer] = useState('675878');
  const startParam = searchParams.get('start'); // Get the 'start' parameter
  const dispatch = useDispatch();
  
  const { userId,wallet_address } = useSelector((state) => state.user.value);
  console.log("orignal userId ",userId)
  useEffect(()=>{
    const data = {
      userId : userId
    }
    guestRegister(data).then((res) => {
      //console.log("fff ",res)
      setsponcer(res?.data?.referrerId)
      setstkincome(res?.data?.wallet_income)
      setStaking(res?.totalStake)
      setdeliincome(res?.perday)
      setrank(res?.rank)
    })

  },[userId])

  useEffect(() => {
    const fata = {
      walletAddress: wallet_address
    };
  
    checkRank(fata).then((res) => {
      console.log(res);
    }).catch((error) => {
      console.error('Error checking rank:', error);
    });
  }, [wallet_address]);
  
  return (
    <div className="dashboard">
      {/* Logo Section */}
      <div className="logo">
        <img src="/new2.png" alt="Logo" />
      </div>

      {/* Header Section */}
      <div className="header">
        <div className="user-info">
        <p>Sponsor Id : {sponcer}</p>
        <p>My Id : {userId}</p>
        </div>
        <div className="faq-icon">
          <p>Rank :</p>
          <div className="chat-icon">{rank}</div>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="content">
        {/* Profit Section */}
        <div className="profit-section">
          <div className="profit-box">
            <p>Active Stake</p>
            <div className="profit-value">
              <img src="/usdt-icon.png" alt="USDT" />
              <p>+{mystakings} $</p> {/* This will be replaced with USDT symbol */}
            </div>
          </div>
          <div className="profit-box">
            <p>Profit per Day</p>
            <div className="profit-value">
              <img src="/usdt-icon.png" alt="USDT" />
              <p>+{dailyIncome} $</p> {/* This will be replaced with USDT symbol */}
            </div>
          </div>
        </div>

        {/* Balance Section */}
        <div className="balance-section">
        <h1>
    {`$ ${Number(walletIncome).toFixed(3) || '0.000'}`}
</h1>
        </div>

        {/* Buttons Section */}
        <div className="buttons-section">
          <Link to="/withdraw">
          <button className="withdraw-btn">WITHDRAW</button>
          </Link>
          <Link to="/mine">
          <button className="mine-btn">STAKE</button>
          </Link>
        </div>

        {/* USDT Logo */}
        <div className="usdt-logo">
          <img src="/usdt-logo.png" alt="USDT" />
        </div>

        {/* Footer with energy */}
        {/* <div className="energy-section">
          <p>1000 / 1000 ⚡</p>
        </div> */}
      </div>

      {/* Fixed Navigation Bar */}
     
    </div>
  );
};

export default Dashboard;