import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Mine from './Mine';
import Withdraw from './Withdraw';
import PaymentScreen from './PaymentScreen';
import Partners from './ReferralScreen';
import RankDetail from './RankDetail';
import Profile from './ProfileUpdate';
import Navbar from './NavBar'; // Import your Navbar component
import AllStakingList from './AllStakingList';
import { useDispatch } from 'react-redux';
import { guestRegister } from './helper/api';
import { setUserId, setWalletAddress } from './helper/redux/dataSlice';
import LevelIncome from './LevelIncome';
import DailyRoi from './DailyRoi';
const App = () => {
  let userId = window.Telegram?.WebApp.initDataUnsafe?.user?.id;
  const dispatch = useDispatch();
  
  console.log("orignal userId ",userId)
  useEffect(()=>{
    const data = {
      userId : userId
    }
    guestRegister(data).then((res) => {
      //console.log("fff ",res)
      dispatch(setWalletAddress({ wallet_address: res?.data?.user }));
    })
    dispatch(setUserId({ userId: userId }));
    
  },[userId])
  return (
    <Router>
      <div className="app">
        {/* NavBar is outside Routes so it appears on every page */}
        <Navbar />

        {/* Define routes */}
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/mine" element={<Mine />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/partner" element={<Partners />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/rankdetail" element={<RankDetail />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/staking" element={<AllStakingList />} />
          <Route path="/levelIncome" element={<LevelIncome />} />
          <Route path="/dailyroi" element={<DailyRoi />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;