import React, { useState, useEffect } from 'react';
import './RankDetail.css'; // Add appropriate styling for RankDetail
import { allRank } from './helper/api';
import { useSelector } from 'react-redux';

const RankDetail = () => {
    const { wallet_address } = useSelector((state) => state.user.value);
    const [ranks, setRanks] = useState([
        { rank: 'PLATINUM', bonus: '15%' },
        { rank: 'GOLD', bonus: '20%' },
        { rank: 'DIAMOND', bonus: '25%' },
        { rank: 'CROWN', bonus: '30%' },
        { rank: 'ROYAL CROWN', bonus: '35%' }
    ]);

    useEffect(() => {
        const fata = {
            wallet_address: wallet_address
        };

        allRank(fata).then((res) => {
            console.log(res);
            // Map over the existing ranks without using the current ranks state directly
            const updatedRanks = [
                ...ranks.map(rank => ({
                    ...rank,
                    status: res[rank.rank] ? 'achieved' : 'pending'
                }))
            ];
            setRanks(updatedRanks);
        }).catch((error) => {
            console.error('Error checking rank:', error);
        });
    }, [wallet_address]); // Remove ranks from dependencies

    return (
        <div className="rank-detail">
            <div className="rank-logo">
                <img src="/new2.png" alt="Logo" />
            </div>

            <h3 className="rank-detail-title">Rank Details</h3>

            <table className="rank-detail-table">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>% of Bonus</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {ranks.map((rank, index) => (
                        <tr key={index}>
                            <td>{rank.rank}</td>
                            <td>{rank.bonus}</td>
                            <td className={rank.status === 'achieved' ? 'status-achieved' : 'status-pending'}>
                                {rank?.status?.charAt(0)?.toUpperCase() + rank?.status?.slice(1)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RankDetail;
