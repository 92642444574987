import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Withdraw.css'; // Ensure CSS mirrors the design from your provided screenshot
import { useSelector } from 'react-redux';
import { getBalance, guestRegister, withdrawalHistory, withdrawIncome } from './helper/api';
import toast from 'react-hot-toast';

const Withdraw = () => {
  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { wallet_address, userId } = useSelector((state) => state.user.value);
  const [withdrawList, setwithdrawList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const data = {
      userId: userId
    }
    guestRegister(data).then((res) => {
      //console.log("fff ",res)
      setUserData(res?.data)
    })
  }, [userId,refresh])
  useEffect(() => {
    let fData ={
      wallet_address:wallet_address
    }
    if(wallet_address){
      withdrawalHistory(fData).then((res)=>{

        setwithdrawList(res?.data)
      })
    }
   
  }, [wallet_address,refresh]);

  // Handle input change
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  // Handle the "Withdraw" button click
  const handleWithdrawClick = () => {

    if (amount > 5) {

      let fData = {
        walletAddress: wallet_address,
        amount: amount
      }
      withdrawIncome(fData).then((res) => {
        if (res.status) {
          toast.success(res?.message)
          setRefresh(!refresh)
        } else {
          toast.error(res?.message)
        }

      })
    } else {
      toast.error("Minimum withdwal amount 5$")
    }

  };

  return (
    <div className="withdraw-full-screen">
      <div className="logo-section">
        <img src="/new2.png" alt="Logo" className="logo-img" />
      </div>

      <div className="withdraw-container">
        <div className="wallet-balance">
          <p>Your Wallet Balance: <span>${userData?.wallet_income?.toFixed(3)}</span></p>
        </div>

        <div className="input-section">
          <label htmlFor="amount">Enter amount to withdraw (Min $10):</label>
          <input
            type="number"
            id="amount"
            placeholder="Enter amount in USD"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>

        {errorMessage && <p className="error">{errorMessage}</p>}

        <div className="payment-system">
          <p>Payment system: <span>BEP20 USDT</span></p>
        </div>

        <button className="withdraw-btn" onClick={handleWithdrawClick}>WITHDRAW</button>
      </div>

      <div className="referral-list" style={{ marginTop: '20px' }}>
        <h3>My Withdrawals</h3>
        <table className="referral-table">
          <thead>
            <tr>
            <th>Txn Hash</th>
              <th>Sender Address</th>
              <th>Amount</th>
              <th>Date</th>
            
            </tr>
          </thead>
          <tbody>
            {withdrawList?.map((referral, index) => (
              <tr key={index}>
                 <td>
                    <a 
                      href={`https://bscscan.com/tx/${referral.txHash}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {referral.trxnHash?.slice(0, 4)}...{referral.trxnHash?.slice(-2)}
                    </a>
                  </td>
                  <td>  {referral.send_address?.slice(0, 4)}...{referral.send_address?.slice(-4)}</td>
                  <td>  {referral.withdrawAmount}</td>

               
                <td>{new Date(referral.createdAt).toLocaleString()}</td>
              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdraw;