import React, { useState, useEffect } from 'react';
import './AllStakingList.css'; // Updated CSS filename
import { allstaking, getWallet, levelIcome } from './helper/api';
import { useSelector } from 'react-redux';

const LevelIncome = () => {
  const [stakingList, setStakingList] = useState([]);
  const { userId } = useSelector((state) => state.user.value);
  const { wallet_address } = useSelector((state) => state.user.value);

  // Fetch staking history when address is available
  useEffect(() => {
    const fetchStakingHistory = async () => {
    
      try {
       
        const data = { walletAddress: wallet_address, sortby:"ALL",page:1 };
        const response = await levelIcome(data);
        console.log(response);
        setStakingList(response?.data);
      } catch (error) {
        console.error('Error fetching staking history:', error);
      }
    };

    fetchStakingHistory();
  }, [wallet_address]);
  return (
    <div className="staking-list-screen">
      <div className="logo-section">
        <img src="/new2.png" alt="Logo" className="logo-img" />
      </div>

      <div className="staking-list">
        <h3>All Level Income</h3>

          <table className="staking-table">
            <thead>
              <tr>
                <th>#</th>
               
                <th>Txn Hash</th>
                <th>Level</th>
                <th>Sender</th>
                <th>Income</th>
                {/* <th>Received Amount</th> */}
             
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {stakingList?.length>0 ? 
              
              stakingList?.map((stake, index) => (
                <tr key={stake._id}>
                  <td>{index + 1}</td>
                  <td>
                    <a 
                      href={`https://bscscan.com/tx/${stake.txHash}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {stake.txHash?.slice(0, 4)}...{stake.txHash?.slice(-2)}
                    </a>
                  </td>
                  <td>{stake.level}</td>
                  <td>{stake?.userId  }</td>
                  <td>${stake.income}</td>
                  {/* <td>${stake.income}</td> */}
                  <td>{new Date(stake.createdAt).toLocaleString()}</td>
                </tr>
              )):
              <p>No Income found.</p>

            
            }
            </tbody>
          </table>
       
      </div>
    </div>
  );
};

export default LevelIncome;
