import axios from "axios";
const apiUrl = "https://harvestusdt.io/api/";
//const apiUrl = "http://localhost:8080/api/";

export const guestRegister = async (formData) => {
  try {
    
    const data = await axios.post(`${apiUrl}getData`, formData);

    return data.data;
  } catch (e) {
    console.error("Error in catch:", e);
    return [];
  }
};

export const getWallet = async (formData) => {
    try {
     
      const data = await axios.post(`${apiUrl}getWallet`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return [];
    }
  };

  export const starttracking = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}start-tracking`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return [];
    }
  }; 

  export const allstaking = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}get-stake-history`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const alldirects = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}directmember`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const getBalance = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}getBalance`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const updateWallet = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}updateWallet`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const gettransaction = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}gettransaction`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const levelIcome = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}get-level-stack`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const withdrawIncome = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}withdrawIncome`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const withdrawalHistory = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}withdraw-history`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 


  export const demoStake = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}depositTest`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const DailyRoiData = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}daily-rois`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const checkRank = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}checkandupdateRank`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 

  export const allRank = async (formData) => {
    try {
      console.log("formData ",formData)
      const data = await axios.post(`${apiUrl}checkranks`, formData);
  
      return data.data;
    } catch (e) {
      console.error("Error in catch:", e);
      return []; 
    }
  }; 