import React, { useState, useEffect } from 'react';
import { guestRegister, updateWallet } from './helper/api';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const ProfileUpdate = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { wallet_address } = useSelector((state) => state.user.value);
  const [paymentWallet, SetpaymentWallet] = useState('');
  const { userId } = useSelector((state) => state.user.value);

  useEffect(()=>{
    const data = {
      userId : userId
    }
    guestRegister(data).then((res) => {
      //console.log("fff ",res)
      SetpaymentWallet(res?.data)
    })
  },[userId,isUpdated])

  // Handle wallet address update
  const handleUpdate = async () => {
    if (walletAddress == '') {
      toast.error('Wallet address cannot be empty.')
     
      return;
    }
    try {
      setLoading(true);
     
    
      const data = {wallet_address:walletAddress,user_address:wallet_address};
      const response = await updateWallet(data);
      if (response.status) {
        setSuccess('Wallet address updated successfully!');
        setIsUpdated(true);
        toast.success(response.message)
        setWalletAddress('')
      } else {
        // setError(response.message || 'Failed to update wallet address.');
        toast.error(response.message)
        setWalletAddress('')

      }
    } catch (err) {
      setError('An error occurred during the update.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  console.log(paymentWallet,"paymentWallet:::;")

  return (
    <div className="mine-full-screen">
      <div className="logo-section">
      <img src="/new2.png" alt="Logo" className="logo-img" /> 
      </div>
      
      <div className="mine-container">
        <div className="header">
          <h2>Update Wallet Address</h2>
        </div>
        

          <div>
            {paymentWallet?.wallet_address ? (
              <p className="current-address">Current Wallet Address: {paymentWallet?.wallet_address?.slice(0,6)}...{paymentWallet?.wallet_address?.slice(-6)}</p>
            ) : (
              <p className="no-address">No wallet address linked. Please update your wallet address below.</p>
            )}
            
            <div className="input-section">
              <input
                type="text"
                placeholder="Enter new wallet address"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
              />
            </div>


            <div className="buy-btn-section">
              <button onClick={handleUpdate} className="buy-btn" disabled={loading}>
                {loading ? 'Updating...' : 'Update Wallet Address'}
              </button>
            </div>
          </div>
    
      </div>

      <style jsx>{`
        .mine-full-screen {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          height: 100vh;
          // background-color: #2c2c2c;
          color: white;
        }
        .logo-section {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
        .logo-img {
          max-width: 50%;
        }
        .mine-container {
          width: 90%;
          max-width: 400px;
          padding: 20px;
          background-color: #3d3d3d;
          border-radius: 10px;
          margin-top: 20px;
        }
        .header h2 {
          text-align: center;
        }
        .current-address, .no-address {
          margin-top: 10px;
          text-align: center;
        }
        .input-section {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .input-section input {
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          border: none;
          margin-top: 10px;
        }
        .buy-btn-section {
          text-align: center;
          margin-top: 20px;
        }
        .buy-btn {
          width: 100%;
          padding: 10px;
          background-color: #00b894;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
        }
        .buy-btn:hover {
          background-color: #009a73;
        }
        .error {
          color: red;
          text-align: center;
          margin-top: 10px;
        }
        .success {
          color: green;
          text-align: center;
          margin-top: 10px;
        }
        .loading {
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default ProfileUpdate;
