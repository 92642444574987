import React, { useState } from 'react';
import './BottomSheet.css'; // Import the CSS for the Bottom Sheet
import { setBottomSheetOpen } from './helper/redux/dataSlice';
import { useDispatch, useSelector } from 'react-redux';

const BottomSheet = () => {
  const { bottomSheet } = useSelector((state) => state.user.value);
  const dispatch = useDispatch()
  const handleEarnClick = () => {
    // setIsOpen(!isOpen); // Toggle the bottom sheet
    dispatch(setBottomSheetOpen({ bottomSheet: !bottomSheet }));

  };

  return (
    <>
      <div className="nav-link" onClick={handleEarnClick}>
        <i className="fas fa-user"></i> {/* Earn icon */}
        <span className="nav-text">Me</span>
      </div>

      {bottomSheet && (
        <div className="bottom-sheet">
          <div className="sheet-content">
            <div className="icon-grid">
            <a href='mine'>
              <div className="icon-item">
                <i className="fas fa-coins"></i>
                <span>Stake</span>
              </div>
              </a>
              <a href='withdraw'>
              <div className="icon-item">
                <i className="fas fa-dollar-sign"></i>
                <span>Withdraw</span>
              </div>
              </a>
              <a href='rankdetail'>
              <div className="icon-item">
                <i className="fas fa-star"></i>
                <span>Rank Bonus</span>
              </div>
              </a>
              <a href='profile'>
              <div className="icon-item">
                <i className="fas fa-chart-line"></i>
                <span>Profile</span>
              </div>
              </a>
              <a href='staking'>
              <div className="icon-item">
                <i className="fas fa-trophy"></i>
                <span>All Staking</span>
              </div>
              </a>
              <a href='partner'>
              <div className="icon-item">
                <i className="fas fa-users"></i>
                <span>Referral</span>
              </div>
              </a>
              <a href='levelIncome'>
              <div className="icon-item">
                <i className="fas fa-hand-holding-usd"></i>
                <span>Level Income</span>
              </div>
              </a>
              <a href='dailyroi'>
              <div className="icon-item">
                <i className="fas fa-piggy-bank"></i>
                <span>Daily Roi</span>
              </div>
              </a>
              <div className="icon-item">
                <i className="fas fa-wallet"></i>
                <span>Wallet</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BottomSheet;