import React, { useEffect, useState } from 'react';
import './ReferralScreen.css';
import { useSelector } from 'react-redux';
import { alldirects } from './helper/api';

const ReferralScreen = () => {
  const { userId } = useSelector((state) => state.user.value);
  const { wallet_address } = useSelector((state) => state.user.value);
  const [referralList, setReferralList] = useState([]);
  const [name, setName] = useState();

  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        console.log("address ", wallet_address);
        const data = { wallet_address: wallet_address };
        const response = await alldirects(data);

        setReferralList(response);
        setName(response?.name);
        console.log(referralList);
      } catch (error) {
        console.error('Error fetching referral data:', error);
      }
    };
    if(wallet_address){
    fetchReferralData();
    }
  }, [wallet_address]);

  const handleCopyClick = () => {
    const textToCopy = `https://t.me/harvest_usdt_bot?start=${userId}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("Link copied to clipboard!");
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };
 
  return (
    <div className="referral-screen">
      <div className="logo-section">
        <img src="/new2.png" alt="Logo" className="logo-img" />
      </div>

      <div className="inviter-section">
        <p className="inviter-text">
          Your Inviter: <span className="inviter-name">{name}</span>
        </p>
        <div className="inviter-link">
          <span>https://t.me/harvest_usdt_bot?start={userId}</span>
          <button className="copy-button" onClick={handleCopyClick}>Copy</button>
        </div>
      </div>
      <div className="inviter-section">
        <p className="inviter-text">
          Direct Business: <span className="inviter-name">$ {referralList?.totalStake}</span>
        </p>
      
      </div>

      <div className="referral-list">
        <h3>Your referrals</h3>
        <table className="referral-table">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Join Date</th>
              <th>Total Staking</th>
            </tr>
          </thead>
          <tbody>
            {referralList?.data?.map((referral, index) => (
              <tr key={index}>
                <td>{referral.userId}</td>
                <td>{new Date(referral.createdAt).toLocaleString()}</td>
                <td>${referral.total_staking}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReferralScreen;
