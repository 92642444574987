import { createSlice } from "@reduxjs/toolkit";
const initialValue = {
  userId: "",
  wallet_address: "",
  bottomSheet: false

};
const dataSlice = createSlice({
  name: "dataSlice",
  initialState: {
    value: initialValue,
  },
  reducers: {
    setUserId: (state, action) => {
      state.value.userId = action.payload.userId;
    },
    setWalletAddress: (state, action) => {
      state.value.wallet_address = action.payload.wallet_address;
    },
    setBottomSheetOpen: (state, action) => {
      state.value.bottomSheet = action.payload.bottomSheet;
    }
  },
});

export const { setUserId, setWalletAddress,setBottomSheetOpen} = dataSlice.actions;
export default dataSlice.reducer;